import { render, staticRenderFns } from "./RatePlanItem.vue?vue&type=template&id=202d7acc&scoped=true"
import script from "./RatePlanItem.vue?vue&type=script&setup=true&lang=ts"
export * from "./RatePlanItem.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./RatePlanItem.vue?vue&type=style&index=0&id=202d7acc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_webpack@5.89.0_webpack-cli@5.1.4___lodash@4.17.21_prettie_odnbdxbucp7hbdgt7o7sqsgpyu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "202d7acc",
  null
  
)

export default component.exports