// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v-card .v-badge{position:absolute;z-index:1;top:20px;left:-5px}.v-card .v-badge__badge{border-radius:0;text-transform:uppercase}@media(max-width: 599px){.column-reverse{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:reverse;-ms-flex-direction:column-reverse;flex-direction:column-reverse}.column-reverse .text-h6{font-size:1.125rem !important}.price-from{display:none}}", "",{"version":3,"sources":["webpack://./src/components/Packages/PackagesListItem.vue"],"names":[],"mappings":"AAGA,iBACE,iBAAA,CACA,SAAA,CACA,QAAA,CACA,SAAA,CAEA,wBACE,eAAA,CACA,wBAAA,CAIJ,yBACE,gBACE,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,6BAAA,CAAA,iCAAA,CAAA,6BAAA,CAEA,yBACE,6BAAA,CAIJ,YACE,YAAA,CAAA","sourcesContent":["\n@import '@/app/static/css/variables';\n\n.v-card .v-badge {\n  position: absolute;\n  z-index: 1;\n  top: 20px;\n  left: -5px;\n\n  &__badge {\n    border-radius: 0;\n    text-transform: uppercase;\n  }\n}\n\n@media (max-width: $screen__s - 1) {\n  .column-reverse {\n    display: flex;\n    flex-direction: column-reverse;\n\n    .text-h6 {\n      font-size: 1.125rem !important;\n    }\n  }\n\n  .price-from {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"opacityDisabled": ".5"
};
export default ___CSS_LOADER_EXPORT___;
