import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'
import { setOptions, bootstrap } from 'vue-gtag'
import { isEqual, isEmpty } from 'lodash'
import vueRouter from '../../router'
import {
  isDevMode,
  convertPriceStringToFixedPrecision,
  parseJson,
  log
} from '../../system/helper'
import { useConsent } from '../../composables/useConsent'

let trackingPluginsEnabled = false
const GTM_CONTAINERS = [process.env.HS_GTM_CONTAINER_ID]
const GTAG_IDs = []

const GTM_EVENTS = {
  SET_SEARCH_PARAMS: {
    eventName: 'search',
    lookupMethod: (state) => {
      const {
        promoCode,
        sessionId,
        currencyCode,
        start,
        end,
        nbAdults,
        nbChildren,
        nbInfants,
        roomStayIndex
      } = state.searchParams
      return {
        hotelId: state.route.params.hotelId,
        roomIndex: roomStayIndex,
        arrival: start,
        departure: end,
        numAdults: nbAdults,
        numChildren: nbChildren,
        numInfants: nbInfants,
        promoCode
      }
    }
  }
}

const GA4_EVENTS = {
  ADD_ORDER_TO_ROOM_STAY: {
    eventName: 'add_to_cart',
    lookupMethod: (state, payload) => {
      const {
        order: {
          currency,
          totalPrice,
          totalDiscount,
          productId,
          product,
          services
        }
      } = payload

      return {
        currency,
        value: convertPriceStringToFixedPrecision(totalPrice - totalDiscount),
        items: [
          {
            item_id: productId,
            item_name: product?.room?.roomTypeName,
            coupon: state.currentRoomStay.promotionCode
          },
          ...services.map(
            ({ serviceId, quantity, totalPrice, totalDiscount }) => ({
              item_id: serviceId,
              item_name: state.servicesInfo.find(
                ({ serviceId: id }) => id === serviceId
              )?.name,
              price: convertPriceStringToFixedPrecision(
                totalPrice - totalDiscount
              ),
              discount: convertPriceStringToFixedPrecision(totalDiscount),
              quantity: quantity || 1
            })
          )
        ]
      }
    }
  },
  REMOVE_LAST_ROOM_STAY_ORDER: {
    eventName: 'remove_from_cart',
    lookupMethod: (_state, _payload) => {
      // return {
      //   test: 'test'
      // }
    }
  },
  SET_SEARCH_PARAMS: {
    eventName: 'search',
    lookupMethod: (state) => {
      const {
        promoCode,
        sessionId,
        currencyCode,
        start,
        end,
        nbAdults,
        nbChildren,
        nbInfants
      } = state.searchParams
      return {
        search_term: `Availability search: ${start} - ${end} for ${nbAdults} adults, ${nbChildren} children, and ${nbInfants} infants.${
          promoCode ? ` Promo code ${promoCode} is applicable.` : ''
        } Session ID: ${sessionId}.`
      }
    }
  }
}

const enableTrackingPlugins = (trackers) => {
  if (!isEmpty(trackers)) {
    const { GoogleTagManager, GoogleAnalytics, GoogleGlobalSiteTag } = trackers

    if (GoogleGlobalSiteTag?.tagId && GoogleGlobalSiteTag?.routeId) {
      GTAG_IDs.push({
        id: GoogleGlobalSiteTag.tagId,
        params: { routeId: GoogleGlobalSiteTag.routeId }
      })
    }

    if (GoogleTagManager?.accountId) {
      GTM_CONTAINERS.push(GoogleTagManager.accountId)
    }

    if (GoogleAnalytics?.trackingCodev4) {
      GTAG_IDs.push({
        id: GoogleAnalytics.trackingCodev4
      })
    }
  }

  Vue.use(VueGtm, {
    id: [...new Set(GTM_CONTAINERS)],
    debug: isDevMode(),
    enabled: true,
    vueRouter,
    source: `${process.env.HS_GTM_SS_URL}/gtm.js`
  })

  setOptions({
    includes: GTAG_IDs,
    config: {
      server_container_url: process.env.HS_GTM_SS_URL
    }
  })

  if (shouldUseAnalytics()) {
    bootstrap().then(() => {
      trackingPluginsEnabled = true
    })
  }
}

const useAnalytics = () => {
  const { consent, analytics } = useConsent()
  return { consent, analytics }
}

const shouldUseAnalytics = () => {
  const { consent, analytics } = useAnalytics()
  return consent && analytics
}

const trackGA4Event = (eventName, payload) => {
  if (shouldUseAnalytics()) {
    Vue.$gtag.event(eventName, payload)
  }
}

const trackGTMEvent = (eventName, payload) => {
  if (shouldUseAnalytics()) {
    Vue.gtm?.trackEvent({
      event: eventName,
      ...payload,
      send_to: [...new Set(GTM_CONTAINERS)]
    })
  }
}

export default (store) => {
  store.subscribe((mutation, state) => {
    if (mutation.type === 'SET_CONFIG' && !trackingPluginsEnabled) {
      const { trackers } = mutation.payload
      try {
        const trackingConfig = parseJson(trackers)
        log.info('trackingConfig', trackingConfig)

        enableTrackingPlugins(trackingConfig)
      } catch (e) {
        console.log(`Error parsing trackers config: ${e}`)
      }
    }

    try {
      const mutationName = mutation.type.split('/')[0]
      const eventConfig = GA4_EVENTS[mutationName]
      const eventConfigGTM = GTM_EVENTS[mutationName]

      if (eventConfig) {
        const { eventName, lookupMethod } = eventConfig
        const payload =
          typeof lookupMethod === 'function'
            ? lookupMethod(state, mutation.payload)
            : null
        trackGA4Event(eventName, payload)
      }

      if (eventConfigGTM) {
        const { eventName, lookupMethod } = eventConfigGTM
        const payload = lookupMethod(state, mutation.payload)
        trackGTMEvent(eventName, payload)
      }
    } catch (e) {
      throw new Error(`Error in trackEvent: ${e}`)
    }
  })
}
