import { render, staticRenderFns } from "./RoomCancellationPolicy.vue?vue&type=template&id=15ae44e7&scoped=true&lang=pug"
import script from "./RoomCancellationPolicy.vue?vue&type=script&lang=js"
export * from "./RoomCancellationPolicy.vue?vue&type=script&lang=js"
import style0 from "./RoomCancellationPolicy.vue?vue&type=style&index=0&id=15ae44e7&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_webpack@5.89.0_webpack-cli@5.1.4___lodash@4.17.21_prettie_odnbdxbucp7hbdgt7o7sqsgpyu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15ae44e7",
  null
  
)

export default component.exports