import { render, staticRenderFns } from "./LanguageSelect.vue?vue&type=template&id=7b1d2c98&scoped=true&lang=pug"
import script from "./LanguageSelect.vue?vue&type=script&setup=true&lang=js"
export * from "./LanguageSelect.vue?vue&type=script&setup=true&lang=js"
import style0 from "./LanguageSelect.vue?vue&type=style&index=0&id=7b1d2c98&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_webpack@5.89.0_webpack-cli@5.1.4___lodash@4.17.21_prettie_odnbdxbucp7hbdgt7o7sqsgpyu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b1d2c98",
  null
  
)

export default component.exports