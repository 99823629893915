import Vue from 'vue'
import VueBodyClass from 'vue-body-class'
import VueRouter from 'vue-router'
import { sync } from 'vuex-router-sync'
import store from '../store'
import router from '../router'
import { routes } from '../router/routes'
import {
  normalizeQueryParams,
  isValidHotelId,
  isMaintenanceMode
} from '../system/helper'
import { handleChannelCallback } from '../system/channelManager'
import { isEmpty, omit } from 'lodash'

sync(store, router)

const vueBodyClass = new VueBodyClass(routes)
const isInitialNavigation = (from) => from === VueRouter.START_LOCATION
let isQueryParsed = false
let hotelId

router.beforeEach((to, from, next) => {
  hotelId = to.params.hotelId ?? (to.query.hotelId || to.hash.split('/')[1])

  if (isInitialNavigation(from) && to.hash) {
    Vue.prototype.$hashUrl = true
    next({ path: to.hash.replace('#', ''), query: to.query })
  }

  if (isInitialNavigation(from) && !isValidHotelId(hotelId)) {
    // if hotelId is missing in the URL(path or query param) cancel the navigation and redirect to the env URL
    window.location.href = process.env.NO_HOTEL_REDIRECT_URL
    next(false)
  }

  vueBodyClass.guard(to, next)

  /**
   * At the very first "beforeEach" hook call the app instance isn't injected to the router yet
   * so additional check of the app methods presence is needed
   */
  router.app.shouldSessionBeStarted &&
  router.app.shouldSessionBeStarted(to.name)
    ? store.commit('RESET_SESSION_TIMEOUT', router.app.sessionExpirationHandler)
    : store.commit('CLEAR_SESSION_TIMEOUT')

  // Redirect to maintenance page if the app is in maintenance mode
  if (isMaintenanceMode()) {
    next({ name: 'maintenance' })
  } else if (!isMaintenanceMode() && to.name === 'maintenance') {
    next({ name: 'home' })
  } else {
    next()
  }
})

router.beforeResolve((to, from, next) => {
  if (isInitialNavigation(from) && isValidHotelId(hotelId)) {
    Vue.prototype.$hotelId = hotelId

    if (!isEmpty(to.query) && !isQueryParsed) {
      let query = normalizeQueryParams(to.query)
      isQueryParsed = true
      const { bundleId, sessionId, channelId, route } = query
      const channel = parseInt(channelId)

      if (channel) {
        store.commit('SET_CHANNEL_ID', channel)
        handleChannelCallback(channel, to)
      }

      query = omit(query, ['hotelId', 'route'])

      if (route) {
        switch (route) {
          case 'room':
            next({
              path: `${hotelId}/${route}/${query.roomId}`,
              query: omit(query, ['roomId'])
            })
            break
          case 'bundle':
            next({
              path: `${hotelId}/${route}/${channel}/${sessionId}/${bundleId}`,
              query: omit(query, ['channelId', 'sessionId', 'bundleId'])
            })
            break
          default:
            next({
              path: `${hotelId}`,
              query
            })
        }
      } else {
        if (to.query['hotelId']) {
          next({
            path: `${hotelId}`,
            query
          })
        } else {
          next()
        }
      }
    } else {
      next()
    }
  }
  next()
})
